<template>
    <v-card
      class="d-flex flex-column card-outter ma-1"
      :min-width="minWidth"
      height="100%"
      tile
      outlined
    >

        <div class="iconBox">
          <v-icon x-large :style="{ 'font-size': fontSize + 'px' }">{{
            icon
          }}</v-icon>
        </div>
        <v-card-title class="cardTitle">{{ title }}</v-card-title>

      <v-divider></v-divider>
      <v-card-text>{{ subtitle }}</v-card-text>
  
      <v-spacer></v-spacer>
      <v-card-actions class="actionBox">
        <slot><v-btn variant="outlined"> Button </v-btn></slot>
      </v-card-actions>
      <v-card-text class="py-0"> </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      icon: {
        type: String,
        default: "mdi-file-upload-outline",
      },
      title: {
        type: String,
        default: "This is title",
      },
      subtitle: {
        type: String,
        default: "This is subtitle",
      },
      minWidth: {
        type: Number,
        default: 80,
      },
      fontSize: {
        type: Number,
        default: 80,
      },
      height: {
        type: String,
        default: "100%",
      },
    },
    data() {
      return {};
    },
  };
  </script>
  
  <style scoped>
  .cardTitle {
    font-size: 16px;
  }
  .iconBox {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .actionBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  </style>
  