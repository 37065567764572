<template>
  <div>
    <!-- loading animation -->
    <template v-if="!loaded">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- data not found -->
    <template v-else-if="hasNoResources">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-alert dense type="error">
              <v-row justify="center">
                <v-col class="grow">
                  Unable to find the resource you requested
                </v-col>
                <v-col class="shrink">
                  <v-btn text @click="$router.go(0)">Retry</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-else>
      <br />
      <div v-if="datasetGroup">
        <!-- MMM -->
        <v-sheet
          v-if="datasetGroup.domain.id === 1"
          class="mx-auto ma-1 processStyle"
          rounded="rounded"
          width="90%"
          elevation="1"
        >
          <v-container fluid>
            <v-row style="background: #d9d9d9" class="ma-1">
              <v-col>
                <h3 style="text-align: center">
                  Overview<v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" id="cauasl_discovery_guide">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Show tour guide
                  </v-tooltip>
                </h3>
              </v-col>
            </v-row>
            <v-row justify="center" class="fill-height rowStyle" no-gutters>
              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step1"
                  icon="mdi-file-upload-outline"
                  title="1.Import your data"
                  subtitle="Datasets are required to train models, which can help you
                  optimize your maketing strategies."
                >
                  <div v-for="(item, i) in items" :key="i">
                    <v-container>
                      <v-row class="mb-5 ml-2">{{ item.name }}</v-row>
                      <v-row v-if="item.url" class="ml-2" justify="center">
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        <v-btn
                          icon
                          color="primary"
                          @click="viewFile(item.url, item.name)"
                        >
                          <span>{{ $t("view") }}</span>
                        </v-btn>
                      </v-row>
                      <v-row v-else class="ml-2">
                        <v-btn @click="onUploadDialog(item)" class=""
                          >IMPORT</v-btn
                        >
                      </v-row>
                      <!-- <v-row v-if="item.url && !datasetSchema" class="ml-2">
                        <v-btn @click="onUploadSchemaDialog(item)" class="">
                          IMPORT SCHEMA
                        </v-btn>
                      </v-row>
                      <v-row v-if="item.url && datasetSchema" class="ml-2">
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        <v-btn
                          icon
                          color="primary"
                          @click="onUploadSchemaDialog(item)"
                        >
                          <span class="pl-5">{{ $t("SCHEMA") }}</span>
                        </v-btn>
                      </v-row> -->
                    </v-container>
                  </div>
                </step-card>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step2"
                  icon="mdi-clipboard-plus-outline"
                  title="2. Add open source data"
                  subtitle="Integrate with open source data on the Internet to expand your datasets."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Integrate open source data</v-row>
                    <v-row v-if="hasMergedData" class="ml-2">
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      <v-btn
                        icon
                        color="primary"
                        @click="viewFile(mergedDataUrl, 'Merged_data')"
                      >
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>
                    <v-row v-else-if="isSkipOpenData" class="ml-2">
                      <v-btn text disabled>Skipped</v-btn>
                      <!-- <v-btn
                        text
                        :disabled="isStartOpenDataDisabled"
                        class="mr-2"
                        color="primary"
                        @click="onUploadSchemaDialog"
                        >START</v-btn
                      > -->
                    </v-row>
                    <v-row v-else-if="isMergingData" class="ml-2">
                      <div>Merging data...</div>
                    </v-row>
                    <v-row v-else class="ml-2">
                      <v-btn
                        :disabled="isStartOpenDataDisabled"
                        class="mr-2"
                        @click="onUploadSchemaDialog"
                        >START</v-btn
                      >
                      <v-btn
                        :disabled="isSkipOpenDataDisabled"
                        @click="onSkipOpenData"
                        >SKIP</v-btn
                      >
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step3"
                  icon="mdi-lan"
                  title="3. Generate causality"
                  subtitle="Generate causality from the datasets to gain a explainable model."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Generate causality</v-row>
                    <v-row v-if="isSkipCausality === true">
                      <v-btn text disabled>Skipped</v-btn>
                    </v-row>
                    <v-row v-else-if="causalityStatus === 'init'" class="ml-2">
                      <v-btn
                        :disabled="isStartCausalityDisabled"
                        class="mr-2"
                        @click="onStartCausalGraphDialog"
                        >START</v-btn
                      >
                      <v-btn
                        :disabled="isSkipCausalityDisabled"
                        @click="onSkipCausality"
                        >SKIP</v-btn
                      >
                    </v-row>
                    <v-row v-else-if="causalityStatus === 'pending'">
                      Model is pending to be processed.
                    </v-row>
                    <v-row v-else-if="typeof causalityStatus === 'number'">
                      <v-col>
                        <v-progress-linear
                          v-model="this.cgModel.progress"
                          height="20"
                          style="pointer-events: none"
                        >
                          <strong
                            >{{ Math.ceil(this.cgModel.progress) }}%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      v-else-if="causalityStatus === 'active'"
                      justify="start"
                      class="ml-2"
                    >
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >

                      <v-btn
                        icon
                        color="primary"
                        @click="onViewCausalGraph(false)"
                      >
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>

                    <v-row v-else>
                      <v-col class="ml-2">Error: {{ this.cgModel.step }}</v-col>
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <step-card
                  icon="mdi-cube-scan"
                  title="4. Generate causal impact model"
                  subtitle="Train a model based on the well prepared datasets."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2"
                      >Kairos- Omnichannel solution</v-row
                    >
                    <v-row v-if="appStatus === 'init'" class="ml-2">
                      <v-btn
                        @click="onStartTrainingModel"
                        :disabled="isStartTrainingDisabled"
                        >START</v-btn
                      >
                    </v-row>
                    <v-row v-else-if="appStatus === 'pending'">
                      Model is pending to be processed.
                    </v-row>
                    <v-row v-else-if="typeof appStatus === 'number'">
                      <v-col>
                        <v-progress-linear
                          v-model="this.appModel.progress"
                          height="20"
                          style="pointer-events: none"
                        >
                          <strong
                            >{{ Math.ceil(this.appModel.progress) }}%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      v-else-if="appStatus === 'active'"
                      justify="start"
                      class="ml-2"
                    >
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >

                      <v-btn icon color="primary" @click="onViewResult">
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>
                    <v-row v-else>
                      <v-col class="ml-2"
                        >Error: {{ this.appModel.step }}</v-col
                      >
                    </v-row>
                  </v-container>
                </step-card>

                <!-- <step-card
                  icon="mdi-cube-scan"
                  title="4. Train a model"
                  subtitle="Under Contruction..."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2"> </v-row>
                    <v-row class="ml-2"
                      ><v-btn 
                        :disabled="true"
                        @click="onStartTrainingModel"
                        >START</v-btn
                      ></v-row
                    >
                  </v-container>
                </step-card> -->
              </v-col>

              <!-- <v-col cols="12" sm="12" md="2">
                <step-card
                  icon="mdi-trending-up"
                  title="5. Make predictions"
                  subtitle="Make predictions using your trained models."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2"> </v-row>
                    <v-row class="ml-2"
                      ><v-btn
                        :disabled="isStartTrainingDisabled"
                        @click="viewResult"
                        >VIEW</v-btn
                      ></v-row
                    >
                  </v-container>
                </step-card>
              </v-col> -->
            </v-row>
          </v-container>
        </v-sheet>
        <!-- order forecasting -->
        <v-sheet
          v-if="datasetGroup.domain.id === 2"
          class="mx-auto ma-1 processStyle"
          rounded="rounded"
          width="90%"
          elevation="1"
        >
          <v-container>
            <v-row style="background: #d9d9d9" class="ma-1">
              <v-col>
                <h3 style="text-align: center">Overview</h3>
              </v-col>
            </v-row>
            <v-row justify="space-between" class="fill-height rowStyle">
              <v-col>
                <step-card
                  icon="mdi-file-upload-outline"
                  title="1.Import your data"
                  subtitle="Datasets are required to train models, which can help you
                  optimize your maketing strategies."
                >
                  <!-- <v-row class="mb-5 ml-2">Target time series data</v-row> -->
                  <div v-for="(item, i) in items" :key="i">
                    <v-container>
                      <v-row class="mb-5 ml-2">{{ item.name }}</v-row>
                      <v-row v-if="item.url" class="ml-2" justify="center">
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        Active
                        <v-btn
                          icon
                          color="primary"
                          @click="viewFile(item.url, item.name)"
                        >
                          <span>{{ $t("view") }}</span>
                        </v-btn>
                      </v-row>
                      <v-row v-else class="ml-2">
                        <v-btn @click="onUploadDialog(item)">IMPORT</v-btn>
                      </v-row>
                    </v-container>
                  </div>
                </step-card>
              </v-col>

              <v-col>
                <step-card
                  icon="mdi-clipboard-plus-outline"
                  title="2. Add open source data"
                  subtitle="Integrate with open source data on the Internet to expand your datasets."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Integrate open source data</v-row>
                    <v-row v-if="isSkipOpenData" class="ml-2">
                      <v-btn text disabled>Skipped</v-btn>
                      <!-- <v-btn
                        text
                        :disabled="isStartOpenDataDisabled"
                        class="mr-2"
                        color="primary"
                        @click="onStartOpenSourceData"
                        >START</v-btn
                      > -->
                    </v-row>
                    <v-row v-else class="ml-2">
                      <v-btn
                        :disabled="isStartOpenDataDisabled"
                        @click="onStartOpenSourceData"
                        >START</v-btn
                      >
                      <v-btn
                        :disabled="isSkipOpenDataDisabled"
                        @click="onSkipOpenData"
                        >SKIP</v-btn
                      >
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col>
                <step-card
                  icon="mdi-lan"
                  title="3. Generate causality"
                  subtitle="Generate causality from the datasets to gain a explainable model."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Generate causality</v-row>
                    <v-row v-if="causalityStatus === 'init'" class="ml-2">
                      <v-btn
                        :disabled="isStartCausalityDisabled"
                        @click="onTrainCausalGraph"
                        >START</v-btn
                      >
                    </v-row>
                    <v-row v-else-if="typeof causalityStatus === 'number'">
                      <v-col>
                        <!-- <v-icon> mdi-progress-clock </v-icon>
                        Processing -->
                        <v-progress-linear
                          v-model="this.cgModel.progress"
                          height="20"
                          style="pointer-events: none"
                        >
                          <strong
                            >{{ Math.ceil(this.cgModel.progress) }}%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      v-else-if="causalityStatus === 'active'"
                      justify="start"
                      class="ml-2"
                    >
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >

                      <v-btn
                        icon
                        color="primary"
                        @click="onViewCausalGraph(false)"
                      >
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col>
                <step-card
                  icon="mdi-cube-scan"
                  title="4. Train a model"
                  subtitle="Train a model based on the well prepared datasets."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Target time series data</v-row>
                    <v-row class="ml-2"><v-btn disabled>START</v-btn></v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col>
                <step-card
                  icon="mdi-trending-up"
                  title="5. Make predictions"
                  subtitle="Make predictions using your trained models."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Make predictions</v-row>
                    <v-row class="ml-2"
                      ><v-btn
                        :disabled="isStartTrainingDisabled"
                        @click="onStartTrainingModel"
                        >START</v-btn
                      ></v-row
                    >
                  </v-container>
                </step-card>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
        <!-- Causal Discovery -->
        <v-sheet
          v-if="datasetGroup.domain.id === 3"
          class="mx-auto ma-1 processStyle"
          rounded="rounded"
          width="90%"
          elevation="1"
        >
          <v-container fluid>
            <v-row style="background: #d9d9d9" class="ma-1">
              <v-col>
                <h3 style="text-align: center">
                  Overview
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" id="cauasl_discovery_guide">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Show tour guide
                  </v-tooltip>
                </h3>
              </v-col>
            </v-row>
            <v-row justify="center" class="fill-height rowStyle" no-gutters>
              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step1"
                  icon="mdi-file-upload-outline"
                  title="1.Import your data"
                  subtitle="Datasets are required to train models, which can help you
                  optimize your maketing strategies."
                >
                  <div v-for="(item, i) in items" :key="i">
                    <v-container>
                      <v-row class="mb-5 ml-2">{{ item.name }}</v-row>
                      <v-row v-if="item.url" class="ml-2" justify="center">
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        <v-btn
                          icon
                          color="primary"
                          @click="viewFile(item.url, item.name)"
                        >
                          <span>{{ $t("view") }}</span>
                        </v-btn>
                      </v-row>
                      <v-row v-else class="ml-2">
                        <v-btn @click="onUploadDialog(item)" class=""
                          >IMPORT</v-btn
                        >
                      </v-row>
                      <!-- <v-row v-if="item.url && !datasetSchema" class="ml-2">
                        <v-btn @click="onUploadSchemaDialog(item)" class="">
                          IMPORT SCHEMA
                        </v-btn>
                      </v-row>
                      <v-row v-if="item.url && datasetSchema" class="ml-2">
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        <v-btn
                          icon
                          color="primary"
                          @click="onUploadSchemaDialog(item)"
                        >
                          <span class="pl-5">{{ $t("SCHEMA") }}</span>
                        </v-btn>
                      </v-row> -->
                    </v-container>
                  </div>
                </step-card>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step2"
                  icon="mdi-clipboard-plus-outline"
                  title="2. Add open source data"
                  subtitle="Integrate with open source data on the Internet to expand your datasets."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Integrate open source data</v-row>
                    <v-row v-if="hasMergedData" class="ml-2">
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      <v-btn
                        icon
                        color="primary"
                        @click="viewFile(mergedDataUrl, 'Merged_data')"
                      >
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>
                    <v-row v-else-if="isSkipOpenData" class="ml-2">
                      <v-btn text disabled>Skipped</v-btn>
                      <!-- <v-btn
                        text
                        :disabled="isStartOpenDataDisabled"
                        class="mr-2"
                        color="primary"
                        @click="onUploadSchemaDialog"
                        >START</v-btn
                      > -->
                    </v-row>
                    <v-row v-else-if="isMergingData" class="ml-2">
                      <div>Merging data...</div>
                    </v-row>
                    <v-row v-else class="ml-2">
                      <v-btn
                        :disabled="isStartOpenDataDisabled"
                        class="mr-2"
                        @click="onUploadSchemaDialog"
                        >START</v-btn
                      >
                      <v-btn
                        :disabled="isSkipOpenDataDisabled"
                        @click="onSkipOpenData"
                        >SKIP</v-btn
                      >
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <step-card
                  id="step3"
                  icon="mdi-lan"
                  title="3. Generate causality"
                  subtitle="Generate causality from the datasets to gain a explainable model."
                >
                  <v-container>
                    <v-row class="mb-5 ml-2">Generate causality</v-row>
                    <v-row v-if="causalityStatus === 'init'" class="ml-2">
                      <v-btn
                        :disabled="isStartCausalityDisabled"
                        @click="onStartCausalGraphDialog"
                        >START</v-btn
                      >
                    </v-row>
                    <v-row v-else-if="causalityStatus === 'pending'">
                      Model is pending to be processed.
                    </v-row>
                    <v-row v-else-if="typeof causalityStatus === 'number'">
                      <v-col>
                        <v-progress-linear
                          v-model="this.cgModel.progress"
                          height="20"
                          style="pointer-events: none"
                        >
                          <strong
                            >{{ Math.ceil(this.cgModel.progress) }}%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row
                      v-else-if="causalityStatus === 'active'"
                      justify="start"
                      class="ml-2"
                    >
                      <v-icon color="green"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >

                      <v-btn
                        icon
                        color="primary"
                        @click="onViewCausalGraph(false)"
                      >
                        <span>{{ $t("view") }}</span>
                      </v-btn>
                    </v-row>
                    <v-row v-else>
                      <v-col class="ml-2">Error: {{ this.cgModel.step }}</v-col>
                    </v-row>
                  </v-container>
                </step-card>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </div>
    </template>

    <!-- upload dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title v-if="this.selectedDataset">
          <span class="text-h5"
            >Upload {{ this.selectedDataset.name }} dataset</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  :label="$t('fileInputCSV')"
                  accept=".csv"
                  outlined
                  dense
                  show-size
                  v-model="uploadFile"
                  :error-messages="uploadFileErrors"
                  @input="$v.uploadFile.$touch()"
                  @blur="$v.uploadFile.$touch()"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseUpload">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="onUpload"> Upload </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- upload schema dialog -->
    <v-dialog v-model="dialogSchema" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Upload dataset schema and add open source data
          </span>
          <span>*Skip this step, if no need to add open source data</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-select
                    v-model="schema.datasetSchemaTimestamp"
                    :items="datasetHeaders"
                    label="Timestamp"
                    required
                    dense
                    :error-messages="datasetSchemaTimestampErrors"
                    @input="$v.schema.datasetSchemaTimestamp.$touch()"
                    @blur="$v.schema.datasetSchemaTimestamp.$touch()"
                  ></v-select>
                  <v-select
                    v-model="schema.datasetSchemaFrequency"
                    :items="schema.datasetSchemaFrequencyList"
                    label="Frequency"
                    required
                    dense
                    :error-messages="datasetSchemaFrequencyErrors"
                    @input="$v.schema.datasetSchemaFrequency.$touch()"
                    @blur="$v.schema.datasetSchemaFrequency.$touch()"
                  ></v-select>
                  <v-select
                    v-model="schema.datasetSchemaRegion"
                    :items="colnameForDatasetSchemaRegion"
                    label="Region"
                    required
                    dense
                    :error-messages="datasetSchemaRegionErrors"
                    @input="$v.schema.datasetSchemaRegion.$touch()"
                    @blur="$v.schema.datasetSchemaRegion.$touch()"
                  ></v-select>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="onSkipOpenDataBtn">
            Skip
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseUploadSchema">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="onUploadSchema">
            Save and continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- create causal graph model dialog -->
    <v-dialog v-model="dialogCausalGraph" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Create Causal Graph Model </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-text-field
                    v-model="causalGraphModel.name"
                    label="Model name"
                    :error-messages="causalGraphModelNameErrors"
                    required
                    @change="$v.causalGraphModel.name.$touch()"
                    @blur="$v.causalGraphModel.name.$touch()"
                    dense
                  ></v-text-field>
                  <v-select
                    v-model="causalGraphModel.datasetSchemaTargetValue"
                    :items="colnameForDatasetSchemaTargetValue"
                    label="Target value (Optional)"
                    dense
                    :error-messages="datasetSchemaTargetValueErrors"
                    @input="
                      $v.causalGraphModel.datasetSchemaTargetValue.$touch()
                    "
                    @blur="
                      $v.causalGraphModel.datasetSchemaTargetValue.$touch()
                    "
                  ></v-select>
                  <!-- <v-select
                    v-model="causalGraphModel.categoryColumns"
                    :items="colnameForCategoryColumns"
                    label="Category Columns"
                    required
                    dense
                    chips
                    multiple
                  ></v-select> -->
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="causalGraphModel.epoches"
                        label="Epoches"
                        required
                        dense
                        type="number"
                        :error-messages="epochesErrors"
                        @input="$v.causalGraphModel.epoches.$touch()"
                        @blur="$v.causalGraphModel.epoches.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                        v-model="causalGraphModel.isDag"
                        inset
                        label="DAG"
                        class="mt-0"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-textarea
                    label="Descripstion"
                    v-model="causalGraphModel.description"
                    rows="1"
                    row-height="20"
                  ></v-textarea>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Advanced settings</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <!-- <div>Adjustable parameters</div> -->
                        <!-- <div
                          v-for="item in adjustableCausalGraphModelHyperparameters"
                          :key="item.name"
                        >
                          <v-text-field
                            v-model="causalGraphModel.adjustableHyperparameters"
                            :label="item.display_name"
                            required
                            dense
                            type="number"
                          ></v-text-field>
                        </div> -->
                        <div class="mb-3">Model</div>

                        <v-text-field
                          v-model="causalGraphModel.neurons"
                          label="Neurons/layer"
                          required
                          dense
                          type="number"
                          :error-messages="neuronsErrors"
                          @input="$v.causalGraphModel.neurons.$touch()"
                          @blur="$v.causalGraphModel.neurons.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.layers"
                          label="Layers"
                          required
                          dense
                          type="number"
                          :error-messages="layersErrors"
                          @input="$v.causalGraphModel.layers.$touch()"
                          @blur="$v.causalGraphModel.layers.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.t_seed"
                          label="Seed"
                          required
                          dense
                          type="number"
                          :error-messages="t_seedErrors"
                          @input="$v.causalGraphModel.t_seed.$touch()"
                          @blur="$v.causalGraphModel.t_seed.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.t_batch_size"
                          label="Batch size"
                          required
                          dense
                          type="number"
                          :error-messages="t_batch_sizeErrors"
                          @input="$v.causalGraphModel.t_batch_size.$touch()"
                          @blur="$v.causalGraphModel.t_batch_size.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.t_lr_rate"
                          label="LR rate"
                          required
                          dense
                          type="number"
                          :error-messages="t_lr_rateErrors"
                          @input="$v.causalGraphModel.t_lr_rate.$touch()"
                          @blur="$v.causalGraphModel.t_lr_rate.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.t_l2_norm"
                          label="L2 norm"
                          required
                          dense
                          type="number"
                          :error-messages="t_l2_normErrors"
                          @input="$v.causalGraphModel.t_l2_norm.$touch()"
                          @blur="$v.causalGraphModel.t_l2_norm.$touch()"
                        ></v-text-field>

                        <div class="mb-3">Causal graph search</div>
                        <v-text-field
                          v-model="causalGraphModel.dag_iterations"
                          label="Number of iterations"
                          required
                          dense
                          type="number"
                          :error-messages="dag_iterationsErrors"
                          @input="$v.causalGraphModel.dag_iterations.$touch()"
                          @blur="$v.causalGraphModel.dag_iterations.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.dag_lr_rate"
                          label="LR rate"
                          required
                          dense
                          type="number"
                          :error-messages="dag_lr_rateErrors"
                          @input="$v.causalGraphModel.dag_lr_rate.$touch()"
                          @blur="$v.causalGraphModel.dag_lr_rate.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.dag_h_lambda"
                          label="H lambda"
                          required
                          dense
                          type="number"
                          :error-messages="dag_h_lambdaErrors"
                          @input="$v.causalGraphModel.dag_h_lambda.$touch()"
                          @blur="$v.causalGraphModel.dag_h_lambda.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="causalGraphModel.dag_l2_lambda"
                          label="L2 lambda"
                          required
                          dense
                          type="number"
                          :error-messages="dag_l2_lambdaErrors"
                          @input="$v.causalGraphModel.dag_l2_lambda.$touch()"
                          @blur="$v.causalGraphModel.dag_l2_lambda.$touch()"
                        ></v-text-field>

                        <!-- <div class="mb-3">Response curve</div>
                        <v-text-field
                          v-model="causalGraphModel.dag_iterations"
                          label="Sample number"
                          required
                          dense
                          type="number"
                          :error-messages="dag_iterationsErrors"
                          @input="$v.causalGraphModel.dag_iterations.$touch()"
                          @blur="$v.causalGraphModel.dag_iterations.$touch()"
                        ></v-text-field> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseCausalGraphModel">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="onTrainCausalGraph">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import data from "@/models/data";
import StepCard from "@/components/atoms/StepCard";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import _ from "lodash";

const fileSizeRule = (file) => {
  if (file && file.size > 200 * 1024 * 1024) {
    // 200 MB in bytes
    return false;
  }
  return true;
};

export default {
  inject: ["datasetName"],
  name: "DatasetGroupsOverview",
  components: {
    StepCard,
  },
  data() {
    return {
      loaded: true,
      hasNoResources: null,
      isModelCreateActive: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Action", value: "actions" },
      ],
      datasetGroup: null,
      items: [
        // {
        //   name: "Data",
        //   description:
        //     "Time series dataset such as sales records in the past few years",
        //   data: "123",
        // },
        // {
        //   name: "Price",
        //   description: "Price of each marketing tactics",
        //   data: null,
        // },
        // {
        //   name: "Group",
        //   description: "Groups of each marketing tactics",
        //   data: null,
        // },
      ],
      dialog: false,
      dialogSchema: false,
      selectedDataset: null,
      uploadFile: null,
      onUploadBtnLocked: false,
      onUploadSchemaBtnLocked: false,
      isSkipOpenData: false,
      isStartCausality: false,
      isProcessingCausality: false,
      datasetSchema: null,
      datasetHeaders: null,
      schema: {
        datasetSchemaFrequency: null,
        datasetSchemaTimestamp: null,
        datasetSchemaRegion: null,
        datasetSchemaFrequencyList: ["monthly", "quarterly", "annual"],
        // datasetSchemaTargetValue: null,
      },
      isMergingData: null,
      hasMergedData: false,
      mergedDataUrl: null,
      causalGraphDag: null,
      causalGraphModel: {
        name: null,
        datasetSchemaTargetValue: null,
        categoryColumns: null,
        epoches: 10000,
        isDag: true,
        description: null,
        hyperparameters: {
          // t_neurons: [256, 256, 256, 256, 256],
          // t_seed: 1234,
          // t_epochs: 10000,
          // t_batch_size: 32,
          // t_lr_rate: 0.0001,
          // t_l2_norm: 0,
          // dag_iterations: 20000,
          // dag_lr_rate: 0.0001,
          // dag_h_lambda: 1,
          // dag_l2_lambda: 0.01,
        },
        t_seed: 1234,
        t_neurons: [],
        t_batch_size: 32,
        t_lr_rate: 0.0001,
        t_l2_norm: 0,
        dag_iterations: 20000,
        dag_lr_rate: 0.0001,
        dag_h_lambda: 1,
        dag_l2_lambda: 0.01,
        neurons: 256,
        layers: 8,
      },
      dialogCausalGraph: null,
      cgModel: null,
      appModel: null,
      experiment: {
        created_at: "2023-08-21 07:55:12.314",
        deleted_at: null,
        id: 1,
        is_deleted: false,
        project_id: 1,
        ticked_at: "2023-08-21 07:59:40.811",
        workflow: {
          name: "MMM",
          start: "0",
          steps: {
            "0": {
              description: "upload dataset",
              ended_at: "2023-08-21 08:59:40.811",
              from_steps: [],
              id: "0",
              is_manual: true,
              name: "upload dataset",
              progress: 100,
              stage: "done",
              started_at: "2023-08-21 07:59:40.811",
              status: "done",
              ticked_at: "2023-08-21 08:01:40.132",
              to_steps: ["1"],
            },
            "1": {
              description: "select external data",
              ended_at: "2023-08-21 09:10:40.811",
              from_steps: ["0"],
              id: "1",
              is_manual: true,
              name: "select external data",
              progress: 100,
              stage: "done",
              started_at: "2023-08-21 09:00:40.811",
              status: "skipped",
              ticked_at: "2023-08-21 09:05:40.811",
              to_steps: ["2", "3"],
            },
            "2": {
              description: "step 2",
              ended_at: null,
              from_steps: ["1"],
              id: "2",
              is_manual: true,
              name: "step 2",
              progress: 0,
              stage: "",
              started_at: null,
              status: "standby",
              ticked_at: null,
              to_steps: ["4"],
            },
            "3": {
              description: "step 3",
              ended_at: null,
              from_steps: ["1"],
              id: "3",
              is_manual: true,
              name: "step 3",
              progress: 0,
              stage: "",
              started_at: null,
              status: "standby",
              ticked_at: null,
              to_steps: ["4"],
            },
          },
          version: "1.0",
        },
      },
      pollingInterval: null,
      isStartOpenDataDisabled: true,
      isSkipOpenDataDisabled: true,
      isSkipCausalityDisabled: true,
      isSkipCausality: false,
      causalityStatus: "init",
    };
  },
  validations: {
    uploadFile: {
      required,
      fileSizeRule,
    },
    schema: {
      datasetSchemaFrequency: {
        required,
      },
      datasetSchemaTimestamp: {
        required,
      },
      datasetSchemaRegion: {
        required,
      },
      // datasetSchemaTargetValue: {
      //   required,
      // },
    },
    causalGraphModel: {
      name: {
        required,
      },
      datasetSchemaTargetValue: {
        // required,
      },
      epoches: {
        required,
        minValue: minValue(1000),
        maxValue: maxValue(10000),
      },
      t_batch_size: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(2048),
      },
      t_lr_rate: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
      t_l2_norm: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
      dag_iterations: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000),
      },
      dag_lr_rate: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
      dag_h_lambda: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
      dag_l2_lambda: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
      neurons: {
        required,
        minValue: minValue(256),
        maxValue: maxValue(4096),
      },
      layers: {
        required,
        minValue: minValue(3),
        maxValue: maxValue(10),
      },
      t_seed: {
        required,
        minValue: minValue(1000),
        maxValue: maxValue(9999),
      },
    },
  },
  computed: {
    uploadFileErrors() {
      const errors = [];
      if (!this.$v.uploadFile.$dirty) return errors;
      !this.$v.uploadFile.required && errors.push(this.$t("required"));
      !this.$v.uploadFile.fileSizeRule &&
        errors.push("File size should not exceed 200 MB!");
      return errors;
    },
    datasetSchemaFrequencyErrors() {
      const errors = [];
      if (!this.$v.schema.datasetSchemaFrequency.$dirty) return errors;
      !this.$v.schema.datasetSchemaFrequency.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    datasetSchemaTimestampErrors() {
      const errors = [];
      if (!this.$v.schema.datasetSchemaTimestamp.$dirty) return errors;
      !this.$v.schema.datasetSchemaTimestamp.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    datasetSchemaRegionErrors() {
      const errors = [];
      if (!this.$v.schema.datasetSchemaRegion.$dirty) return errors;
      !this.$v.schema.datasetSchemaRegion.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    datasetSchemaTargetValueErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.datasetSchemaTargetValue.$dirty)
        return errors;
      // !this.$v.causalGraphModel.datasetSchemaTargetValue.required &&
      //   errors.push(this.$t("required"));
      return errors;
    },
    epochesErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.epoches.$dirty) return errors;
      !this.$v.causalGraphModel.epoches.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.epoches.minValue &&
        errors.push("Minimun value for epoches is 1000.");
      !this.$v.causalGraphModel.epoches.maxValue &&
        errors.push("Maximun value for epoches is 10000.");
      return errors;
    },
    t_batch_sizeErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.t_batch_size.$dirty) return errors;
      !this.$v.causalGraphModel.t_batch_size.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.t_batch_size.minValue &&
        errors.push("Minimun value for t_batch_size is 1.");
      !this.$v.causalGraphModel.t_batch_size.maxValue &&
        errors.push("Maximun value for t_batch_size is 2048.");
      return errors;
    },
    t_lr_rateErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.t_lr_rate.$dirty) return errors;
      !this.$v.causalGraphModel.t_lr_rate.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.t_lr_rate.minValue &&
        errors.push("Minimun value for t_lr_rate is 0.");
      !this.$v.causalGraphModel.t_lr_rate.maxValue &&
        errors.push("Maximun value for t_lr_rate is 1.");
      return errors;
    },
    t_l2_normErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.t_l2_norm.$dirty) return errors;
      !this.$v.causalGraphModel.t_l2_norm.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.t_l2_norm.minValue &&
        errors.push("Minimun value for t_l2_norm is 0.");
      !this.$v.causalGraphModel.t_l2_norm.maxValue &&
        errors.push("Maximun value for t_l2_norm is 1.");
      return errors;
    },
    dag_iterationsErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.dag_iterations.$dirty) return errors;
      !this.$v.causalGraphModel.dag_iterations.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.dag_iterations.minValue &&
        errors.push("Minimun value for dag_iterations is 1.");
      !this.$v.causalGraphModel.dag_iterations.maxValue &&
        errors.push("Maximun value for dag_iterations is 100000.");
      return errors;
    },
    dag_lr_rateErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.dag_lr_rate.$dirty) return errors;
      !this.$v.causalGraphModel.dag_lr_rate.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.dag_lr_rate.minValue &&
        errors.push("Minimun value for dag_lr_rate is 0.");
      !this.$v.causalGraphModel.dag_lr_rate.maxValue &&
        errors.push("Maximun value for dag_lr_rate is 1.");
      return errors;
    },
    dag_h_lambdaErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.dag_h_lambda.$dirty) return errors;
      !this.$v.causalGraphModel.dag_h_lambda.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.dag_h_lambda.minValue &&
        errors.push("Minimun value for dag_h_lambda is 0.");
      !this.$v.causalGraphModel.dag_h_lambda.maxValue &&
        errors.push("Maximun value for dag_h_lambda is 1.");
      return errors;
    },
    dag_l2_lambdaErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.dag_l2_lambda.$dirty) return errors;
      !this.$v.causalGraphModel.dag_l2_lambda.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.dag_l2_lambda.minValue &&
        errors.push("Minimun value for dag_l2_lambda is 0.");
      !this.$v.causalGraphModel.dag_l2_lambda.maxValue &&
        errors.push("Maximun value for dag_l2_lambda is 1.");
      return errors;
    },
    neuronsErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.neurons.$dirty) return errors;
      !this.$v.causalGraphModel.neurons.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.neurons.minValue &&
        errors.push("Minimun value for neurons is 256.");
      !this.$v.causalGraphModel.neurons.maxValue &&
        errors.push("Maximun value for neurons is 4096.");
      return errors;
    },
    layersErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.layers.$dirty) return errors;
      !this.$v.causalGraphModel.layers.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.layers.minValue &&
        errors.push("Minimun value for layers is 3.");
      !this.$v.causalGraphModel.layers.maxValue &&
        errors.push("Maximun value for layers is 10.");
      return errors;
    },
    t_seedErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.t_seed.$dirty) return errors;
      !this.$v.causalGraphModel.t_seed.required &&
        errors.push(this.$t("required"));
      !this.$v.causalGraphModel.t_seed.minValue &&
        errors.push("Minimun value for t_seed is 1000.");
      !this.$v.causalGraphModel.t_seed.maxValue &&
        errors.push("Maximun value for t_seed is 9999.");
      return errors;
    },
    // isStartOpenDataDisabled() {
    //   return this.items.some((item) => {
    //     return item.url === null && this.datasetSchema === null;
    //   });
    // },
    // isSkipOpenDataDisabled() {
    //   return this.items.some((item) => {
    //     return item.url === null && this.datasetSchema === null;
    //   });
    // },
    isStartCausalityDisabled() {
      return !this.isStartCausality;
    },
    // causalityStatus() {
    //   if (!this.cgModel) {
    //     return "init";
    //   } else if (this.cgModel.is_done && this.cgModel.progress === 100) {
    //     return "active";
    //   } else if (this.cgModel.step && this.cgModel.step.startsWith("Failed")) {
    //     return `Error: ${this.cgModel.step}`;
    //   } else if (this.cgModel.step === "Pending") {
    //     return "pending";
    //   } else if (this.cgModel.progress !== 100) {
    //     return this.cgModel.progress;
    //   } else {
    //     return "error";
    //   }
    // },
    appStatus() {
      if (!this.appModel) {
        return "init";
      } else if (this.appModel.is_done && this.appModel.progress === 100) {
        return "active";
      } else if (
        this.appModel.step &&
        this.appModel.step.startsWith("Failed")
      ) {
        return `Error: ${this.appModel.step}`;
      } else if (this.appModel.step === "Pending") {
        return "pending";
      } else if (this.appModel.progress !== 100) {
        return this.appModel.progress;
      } else {
        return "error";
      }
    },
    isStartTrainingDisabled() {
      if (this.causalityStatus === "active") {
        return false;
      } else if (this.isSkipCausality === true) {
        return false;
      } else {
        return true;
      }
    },
    colnameForDatasetSchemaRegion() {
      return _.filter(this.datasetHeaders, (value) => {
        return value !== this.schema.datasetSchemaTimestamp;
      });
    },
    colnameForDatasetSchemaTargetValue() {
      return _.filter(this.colnameForDatasetSchemaRegion, (value) => {
        return value !== this.schema.datasetSchemaRegion;
      });
    },
    colnameForCategoryColumns() {
      return _.filter(this.colnameForDatasetSchemaTargetValue, (value) => {
        return value !== this.causalGraphModel.datasetSchemaTargetValue;
      });
    },
    causalGraphModelNameErrors() {
      const errors = [];
      if (!this.$v.causalGraphModel.name.$dirty) return errors;
      !this.$v.causalGraphModel.name.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    fixedCausalGraphModelHyperparameters() {
      return _.filter(this.causalGraphModel.hyperparameters, (p) => {
        return p.is_fixed === true;
      });
    },
    adjustableCausalGraphModelHyperparameters() {
      return _.filter(this.causalGraphModel.hyperparameters, (p) => {
        return p.is_fixed === false;
      });
    },
  },
  methods: {
    async getDatasets() {
      const id = this.$route.params.id;
      try {
        const res = await data.getDatasets(id);
        // this.columns = res.data.columns;
        // this.rows = res.data.rows;
        // console.log("getDatasets:", res);
        let items = [];
        res.forEach((dataset) => {
          const obj = {
            id: dataset.id,
            name: dataset.name,
            description: dataset.description,
            url: dataset.url,
            category_id: dataset.category_id,
            merged_url: dataset.merged_url,
          };
          items.push(obj);
        });
        items = _.sortBy(items, ["category_id"]);
        this.items = items.filter((item) => item.name == "data");
        // console.log("this.items", this.items);
        if (this.items[0].merged_url) {
          this.hasMergedData = true;
          this.mergedDataUrl = this.items[0].merged_url;
        }
      } catch (error) {
        console.log("error", error);
        this.hasNoResources = true;
      }
      this.loaded = true;
    },
    onUploadDialog(item) {
      this.dialog = true;
      this.selectedDataset = item;
    },
    // onUploadSchemaDialog(item) {
    //   this.dialogSchema = true;
    //   this.selectedDataset = item;
    // },
    onUploadSchemaDialog() {
      this.dialogSchema = true;
    },
    async onUpload() {
      this.onUploadBtnLocked = true;
      this.$v.uploadFile.$touch();
      if (this.$v.uploadFile.$invalid) {
        // valid
        // console.log("this.$v.uploadFile.$invalid", this.$v.uploadFile.$invalid);
        // if (this.$v.dataset_name.$invalid) {
        //   console.log("this.$v.dataset_name", this.$v.dataset_name.$invalid);
        //   this.$vuetify.goTo("#datasetnameSection");
        // }
        if (this.$v.uploadFile.$invalid) {
          console.log("this.$v.uploadFile", this.$v.uploadFile.$invalid);
          // this.$vuetify.goTo("#fileSection");
        }
      } else {
        const dataset_group_id = this.$route.params.id;
        const payload = {
          dataset_group_id: dataset_group_id,
          category_id: this.selectedDataset.category_id,
          file: this.uploadFile,
        };

        const res = await data.updateDataset(payload);
        // console.log("res", res);
        if (res.status !== 201) {
          this.$dialog.message.warning(this.$t("uploadFileError"), {
            position: "top",
          });
        } else if (res.status === 201) {
          this.$dialog.message.success(this.$t("uploadFileSuccess"), {
            position: "top",
          });
          // close dialog
          this.dialog = false;
          // clear file
          this.uploadFile = null;
          // clear dataset list
          this.items = [];
          // reload dataset list
          await this.getDatasets();
          // reload dataset headers
          await this.getDatasetHeaders();
          // reload train button status
          this.$emit("updateTrainBtnStatus");
          // Get latest status
          await this.getExperiments();
        } else {
          this.$dialog.message.error(this.$t("systemError"), {
            position: "top",
          });
        }
      }
      this.onUploadBtnLocked = false;
    },
    onCloseUpload() {
      this.dialog = false;
      this.$v.uploadFile.$reset();
    },
    async onTrainCausalGraph() {
      const datasetGroupId = this.$route.params.id;

      this.$v.causalGraphModel.$touch();
      if (this.$v.causalGraphModel.$invalid) {
        console.log(
          "this.$v.causalGraphModel.$invalid",
          this.$v.causalGraphModel.$invalid
        );
      } else {
        try {
          const hyperparameters = {};
          this.causalGraphModel.hyperparameters.forEach((p) => {
            if (p.is_fixed === true) {
              hyperparameters[p.name] = p.value;
            }
          });

          this.t_neurons = [];
          for (let i = 0; i < this.causalGraphModel.layers; i++) {
            this.t_neurons.push(parseInt(this.causalGraphModel.neurons));
          }

          hyperparameters["t_neurons"] = this.t_neurons;
          hyperparameters["t_seed"] = parseInt(this.causalGraphModel.t_seed);
          hyperparameters["t_epochs"] = parseInt(this.causalGraphModel.epoches);
          hyperparameters["t_batch_size"] = parseInt(
            this.causalGraphModel.t_batch_size
          );
          hyperparameters["t_lr_rate"] = parseFloat(
            this.causalGraphModel.t_lr_rate
          );
          hyperparameters["t_l2_norm"] = parseFloat(
            this.causalGraphModel.t_l2_norm
          );
          hyperparameters["dag_iterations"] = parseInt(
            this.causalGraphModel.dag_iterations
          );
          hyperparameters["dag_lr_rate"] = parseFloat(
            this.causalGraphModel.dag_lr_rate
          );
          hyperparameters["dag_h_lambda"] = parseFloat(
            this.causalGraphModel.dag_h_lambda
          );
          hyperparameters["dag_l2_lambda"] = parseFloat(
            this.causalGraphModel.dag_l2_lambda
          );

          const payloadCausalGraphModel = {
            datasetGroupId: datasetGroupId,
            is_acyclic: this.causalGraphModel.isDag,
            name: this.causalGraphModel.name,
            description: this.causalGraphModel.description,
            hyperparameters: hyperparameters,
            additional_parameters: {
              target: this.causalGraphModel.datasetSchemaTargetValue,
              category_fields: this.causalGraphModel.categoryColumns,
            },
          };
          // console.log(payloadCausalGraphModel);
          await data.createCausalGraphModel(payloadCausalGraphModel);
          // console.log("res", res);
          this.isProcessingCausality = true;
          // console.log("payloadCausalGraphModel");
          // console.log(payloadCausalGraphModel);
          this.onCloseCausalGraphModel();
          // this.$router.push("/models");

          await this.getExperiments();
        } catch (error) {
          console.log("error", error);
          this.hasNoResources = false;
        }
      }
    },
    async onSkipCausality() {
      const datasetGroupId = this.$route.params.id;
      const payload = {
        datasetGroupId: datasetGroupId,
      };
      const res = await data.createSkipCausalGraph(payload);
      console.log("res");
      console.log(res);
      await this.getExperiments();
    },
    //Peter
    onTrainFakeCausalGraph() {
      // console.log("onTrainFakeCausalGraph");
      this.isProcessingCausality = true;
      setTimeout(() => {
        // console.log(this.datasetGroup);
        this.isProcessingCausality = false;
        this.datasetGroup.graph = "{}";
      }, 1000);
    },
    async getDatasetGroup() {
      try {
        const id = this.$route.params.id;
        const response = await data.getDatasetGroup(id);
        this.datasetGroup = response;
      } catch (error) {
        this.datasetGroup = {};
      }
    },
    async onSkipOpenData() {
      const payload = {
        datasetGroupId: this.$route.params.id,
        datasetId: this.items[0].id,
        skipped: true,
      };

      const res = await data.createMergedDataset(payload);
      console.log("res", res);

      this.isSkipOpenData = true;
      this.isStartCausality = true;
      this.isSkipCausalityDisabled = false;
    },
    async onSkipOpenDataBtn() {
      this.onCloseUploadSchema();
      this.onSkipOpenData();
    },
    onViewCausalGraph(isExpert) {
      const id = this.$route.params.id;
      //this.$router.push(`/datasetGroups/${id}/causal-graph`);
      // this.$router.push(`/datasetGroups/${id}/causal-editor`);
      // console.log(isExpert);
      this.$router.push({
        path: `/datasetGroups/${id}/causal-editor`,
        query: { isExpert: isExpert },
      });
    },
    onStartTrainingModel() {
      const id = this.$route.params.id;
      this.$router.push(`/datasetGroups/${id}/model-create`);
    },
    onStartOpenSourceData() {
      const id = this.$route.params.id;
      this.$router.push(`/datasetGroups/${id}/open-source-data`);
    },
    viewFile(url, title) {
      this.$router.push({
        name: `dataset-groups-data-view`,
        params: { url: url, title: title },
      });
    },
    async onUploadSchema() {
      this.onUploadSchemaBtnLocked = true;
      this.$v.schema.$touch();
      if (this.$v.schema.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.schema.$invalid);
      } else {
        const datasetGroupId = this.$route.params.id;
        const payload = {
          datasetGroupId: datasetGroupId,
          datasetId: this.items[0].id,
          frequency: this.schema.datasetSchemaFrequency,
          schema: {
            Attributes: [
              {
                Name: this.schema.datasetSchemaTimestamp,
                Type: "timestamp",
              },
              {
                Name: this.schema.datasetSchemaRegion,
                Type: "region",
              },
              // {
              //   Name: this.schema.datasetSchemaTargetValue,
              //   Type: "target_value",
              // },
            ],
          },
        };
        const res = await data.updateDatasetSchema(payload);
        // console.log("res", res);
        if (res.status !== 200) {
          this.$dialog.message.warning(this.$t("uploadFileError"), {
            position: "top",
          });
        } else if (res.status === 200) {
          this.$dialog.message.success(this.$t("uploadFileSuccess"), {
            position: "top",
          });
          // close dialog
          this.dialogSchema = false;
          // reload dataset list
          await this.getDatasets();
          // reload dataset schema
          await this.getDatasetSchema();
          // reload dataset headers
          await this.getDatasetHeaders();
          // reload train button status
          this.$emit("updateTrainBtnStatus");
          const id = this.$route.params.id;
          this.$router.push(`/datasetGroups/${id}/open-source-data`);
        } else {
          this.$dialog.message.error(this.$t("systemError"), {
            position: "top",
          });
        }
      }
      this.onUploadSchemaBtnLocked = false;
    },
    onCloseUploadSchema() {
      this.dialogSchema = false;
      this.$v.schema.$reset();
    },
    async getDatasetSchema() {
      const id = this.$route.params.id;
      if (this.items[0].id) {
        try {
          const payload = {
            datasetGroupId: id,
            datasetId: this.items[0].id,
          };
          const res = await data.getDatasetSchema(payload);
          // console.log("getDatasetSchema:", res);

          this.datasetSchema = res;
          // console.log(
          //   'this.datasetSchema["Dataset schema"].schema',
          //   this.datasetSchema["Dataset schema"].schema
          // );
          let schema = this.datasetSchema["Dataset schema"];
          // console.log("schema", schema);
          this.schema.datasetSchemaFrequency = schema.frequency;
          // console.log(
          //   "this.schema.datasetSchemaFrequency",
          //   this.schema.datasetSchemaFrequency
          // );
          // console.log("schema", schema);
          const datasetSchemaTimestamp = _.filter(
            schema?.schema?.Attributes,
            (value) => {
              return value.Type == "timestamp";
            }
          );
          // console.log("datasetSchemaTimestamp", datasetSchemaTimestamp);
          const datasetSchemaRegion = _.filter(
            schema?.schema?.Attributes,
            (value) => {
              // console.log("value", value);
              return value.Type == "region";
            }
          );
          // console.log("datasetSchemaTimestamp", datasetSchemaTimestamp);
          // console.log("datasetSchemaRegion", datasetSchemaRegion[0]);
          this.schema.datasetSchemaTimestamp = datasetSchemaTimestamp
            ? datasetSchemaTimestamp[0]?.Name
            : null;
          this.schema.datasetSchemaRegion = datasetSchemaRegion[0]
            ? datasetSchemaRegion[0]?.Name
            : null;
          // this.schema.datasetSchemaTargetValue =
          //   datasetSchemaTargetValue[0].Name;
        } catch (error) {
          console.log("error", error);
          // if (error.response.status === 400) {
          //   // pass
          // } else {
          //   this.hasNoResources = true;
          // }
        }
        this.loaded = true;
      }
    },
    async getDatasetHeaders() {
      const id = this.$route.params.id;
      // The 500 error of header api shouldd be fixed by backend in the future
      if (this.items[0].id && this.items[0].url) {
        try {
          const payload = {
            datasetGroupId: id,
            datasetId: this.items[0].id,
          };
          const res = await data.getDatasetHeaders(payload);
          // console.log("getDatasetHeaders:", res);
          this.datasetHeaders = res;
        } catch (error) {
          console.log("error", error);
          this.hasNoResources = true;
        }
        this.loaded = true;
      }
    },
    async getCausalGraph() {
      const id = this.$route.params.id;
      try {
        const payload = {
          datasetGroupId: id,
        };
        const res = await data.getCausalGraph(payload);
        // console.log("getCausalGraph:", res);
        this.causalGraphDag = res;
      } catch (error) {
        // console.log("error!!!");
        console.log(error);
        // this.cgModel = { step: null };
        // this.cgModel.step = "Failed on server error.";
        // this.hasNoResources = true;
      }
      this.loaded = true;
    },
    async getModels() {
      const id = this.$route.params.id;
      const payload = {
        datasetGroupId: id,
      };
      const res = await data.getModelsByDatasetGroupsId(payload);
      const model = res.data.models;

      const cgModel = model.find((m) => {
        return m.type === "causal_discovery";
      });

      if (cgModel) {
        this.cgModel = cgModel;
        this.cgModel.created_at = this.cgModel.created_at.split(".")[0];
      }

      const appModel = model.find((m) => {
        return m.type === "mmm";
      });

      if (appModel) {
        this.appModel = appModel;
        this.appModel.created_at = this.appModel.created_at.split(".")[0];
      }
    },
    onStartCausalGraphDialog() {
      this.dialogCausalGraph = true;
    },
    onCloseCausalGraphModel() {
      this.causalGraphModel.name = null;
      this.causalGraphModel.datasetSchemaTargetValue = null;
      this.causalGraphModel.categoryColumns = null;
      this.causalGraphModel.epoches = 10000;
      this.causalGraphModel.isDag = true;
      this.causalGraphModel.description = null;
      this.dialogCausalGraph = false;
    },
    onViewResult() {
      this.$router.push({
        path: "/results",
        query: {
          id: `${this.appModel.id}`,
          datasetGroupId: `${this.appModel.dataset_group_id}`,
        },
      });
    },
    async getExperiments() {
      // console.log("getExperiments");
      // setTimeout(500);
      // if (
      //   this.hasMergedData === false &&
      //   this.experiment.workflow.steps[1].status === "skipped"
      // ) {
      //   this.onSkipOpenData();
      //   // console.log("onSkipOpenData");
      // }
      const id = this.$route.params.id;
      const payload = {
        dsg_id: id,
      };
      const res = await data.getExperiments(payload);
      console.log("res");
      console.log(res);
      console.log(res.experiments);
      console.log(res.experiments[0]);
      console.log(res.experiments[0].workflow.steps.external_data.status);

      // Upload dataset
      const statusUploadDataset =
        res.experiments[0]?.workflow?.steps?.upload_dataset?.status;
      if (statusUploadDataset === "standby") {
        console.log("standby");
      } else if (statusUploadDataset === "done") {
        console.log("done");
        this.isSkipOpenDataDisabled = false;
        this.isStartOpenDataDisabled = false;
      }

      // External data
      const status = res.experiments[0]?.workflow?.steps?.external_data?.status;
      if (status === "standby") {
        console.log("standby");
      } else if (status === "running") {
        console.log("running");
        this.isMergingData = true;
      } else if (status === "skipped") {
        console.log("skipped");
        this.isSkipOpenData = true;
        this.isStartCausality = true;
        this.isSkipCausalityDisabled = false;
      } else if (status === "failed") {
        console.log("failed");
      } else if (status === "done") {
        console.log("done");
        this.isMergingData = false;
        this.isStartCausality = true;
        this.isSkipCausalityDisabled = false;
      } else if (status === "triggered") {
        console.log("triggered");
        this.isMergingData = true;
      } else {
        console.log("else");
      }

      // Causal graph
      const cgStatus =
        res.experiments[0]?.workflow?.steps?.causal_discovery?.status;
      if (cgStatus === "standby") {
        console.log("standby");
        this.causalityStatus = "init";
      } else if (cgStatus === "running") {
        console.log("running");
        this.causalityStatus =
          res.experiments[0]?.workflow?.steps?.causal_discovery?.progress;
      } else if (cgStatus === "skipped") {
        console.log("skipped");
        this.isSkipCausality = true;
      } else if (cgStatus === "failed") {
        console.log("failed");
        this.causalityStatus = `Error`;
      } else if (cgStatus === "done") {
        console.log("done");
        this.causalityStatus = "active";
      } else if (cgStatus === "triggered") {
        console.log("triggered");
        this.causalityStatus = "pending";
      } else {
        console.log("else");
        this.causalityStatus = "error";
      }

      //   causalityStatus() {
      //   if (!this.cgModel) {
      //     return "init";
      //   } else if (this.cgModel.is_done && this.cgModel.progress === 100) {
      //     return "active";
      //   } else if (this.cgModel.step && this.cgModel.step.startsWith("Failed")) {
      //     return `Error: ${this.cgModel.step}`;
      //   } else if (this.cgModel.step === "Pending") {
      //     return "pending";
      //   } else if (this.cgModel.progress !== 100) {
      //     return this.cgModel.progress;
      //   } else {
      //     return "error";
      //   }
      // },
    },
    async getDomains() {
      const res = await data.getDomains();
      // console.log("res");
      // console.log(res);
      this.causalGraphModel.hyperparameters = res.domains[0].hyperparameters;
    },
    async pollData() {
      await this.getDatasets();
      await this.getDatasetGroup();
      // await this.getDatasetSchema();
      // await this.getDatasetHeaders();
      await this.getCausalGraph();
      await this.getModels();
      await this.getExperiments();
      await this.getDomains();
    },
  },
  async created() {
    // this.getColnameCSV();
    await this.getDatasets();
    await this.getDatasetGroup();
    await this.getDatasetSchema();
    await this.getDatasetHeaders();
    await this.getCausalGraph();
    await this.getModels();
    await this.getDomains();
    await this.getExperiments();

    // PETER for Demo
    // this.isSkipOpenData = this.$route.query.isSkipOpenData;
    // this.isStartCausality = this.isStartCausality
    //   ? true
    //   : this.$route.query.isStartCausality;
    // this.isProcessingCausality = this.$route.query.isProcessingCausality;
    // this.datasetGroup.graph = this.$route.query.datasetGroupGraph;

    this.pollingInterval = setInterval(this.pollData, 30000); // Run every 30 sec
  },
  beforeDestroy() {
    // Clear the interval before the component is destroyed
    clearInterval(this.pollingInterval);
  },
};
</script>

<style scope>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
